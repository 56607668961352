<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="800px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Filtre avancé</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-row justify="center">
                  <v-col cols="12" sm="12" md="12" v-if="Product">
                    <v-select
                      :items="[
                        { id: '0', label: 'Produit ' },
                        { id: '1', label: 'Service' },
                      ]"
                      v-model="service"
                      item-text="label"
                      item-value="id"
                      label="Nature du produit"
                      dense
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" v-if="Product">
                    <v-select
                      :items="products_types"
                      v-model="types"
                      item-text="label"
                      item-value="id"
                      label="Type de Produit"
                      dense
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="12">
                    <selecttree
                      :items="categories_list"
                      v-model="categories"
                      :value="categories"
                      :label="'Categories'"
                      dense
                      :clearable="true"
                    >
                    </selecttree>
                  </v-col>
                  <v-col cols="12" sm="7" md="12">
                    <v-autocomplete
                      v-model="template"
                      :items="templates_list"
                      item-value="id"
                      item-text="label"
                      dense
                      label="Attribut de Modele"
                      chips
                      clearable
                      v-if="Product"
                      @change="attributs_sel = []"
                    ></v-autocomplete>
                  </v-col>
                  <v-row justify="center" v-if="template">
                    <v-col cols="12" sm="4" md="4">
                      <v-autocomplete
                        v-model="attribut"
                        :items="attributs_list"
                        item-value="id"
                        item-text="name"
                        dense
                        label="Attribut"
                        small-chips
                        class="mt-2"
                        @change="value = []"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="8" md="4">
                      <v-autocomplete
                        v-model="value"
                        :items="values"
                        item-value="id"
                        item-text="name"
                        dense
                        label="Valeur"
                        small-chips
                        class="mt-2"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="8" md="4">
                      <v-btn
                        small
                        color="indigo"
                        @click="update_attribut"
                        class="mt-2"
                        :disabled="!attribut || !value"
                      >
                        SELECTIONNER
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="12"
                    v-if="attributs_sel.length > 0"
                  >
                    <template>
                      <v-row justify="center">
                        <v-col cols="12" sm="7" md="12" lg="12">
                          <v-sheet elevation="10" rounded="xl">
                            <div class="pa-4">
                              <v-chip-group active-class="primary--text" column>
                                <v-chip
                                  v-for="attribut in attributs_sel"
                                  :key="
                                    attribut.attribute_id + attribut.value_id
                                  "
                                  close
                                  @click:close="delchips(attribut)"
                                  :color="attribut.val_color"
                                >
                                  {{ attribut.attribut_name }} :
                                  {{ attribut.value_name }}
                                </v-chip>
                              </v-chip-group>
                            </div>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ml-2"
              color="indigo"
              @click="filtrer"
              :disabled="
                !template && categories.length == 0 && service.length == 0
              "
            >
              <v-icon> mdi-filter </v-icon>
              Filtrer
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn color="indigo" @click="close" class="ml-2"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
/*function compare(a, b) {
  if (a.attribute_id < b.attribute_id) {
    return -1;
  }
  if (a.attribute_id > b.attribute_id) {
    return 1;
  }
  return 0;
}*/
const selecttree = () => import("../components/SelectTree.vue");

export default {
  name: "filterform",
  components: {
    selecttree,
  },
  props: {
    showForm: Boolean,
    Product: { type: Boolean, default: true },
    categories_list: Array,
    templates_list: Array,
    attributes: Array,
    values_list: Array,
  },
  data: () => ({
    afficher: false,
    valid: true,
    service: [],
    types: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    attributs_sel: [],
    template: "",
    attribut: "",
    value: "",
    Template: {},
    categories: [],
  }),

  computed: {
    products_types() {
      return this.$store.state.products_types.filter(
        (elm) => elm.nature == this.service
      );
    },
    attributs_list() {
      let list = [];
      if (this.template) {
        let modele_attributs = [];
        let i = this.templates_list.findIndex((elm) => elm.id == this.template);
        if (i >= 0) modele_attributs = this.templates_list[i].tabtempattributs;
        for (let index = 0; index < modele_attributs.length; index++) {
          let l = this.values_list.filter(
            (elm) => elm.attribute_id == modele_attributs[index]
          );
          if (l.length >= 0) {
            let k = this.attributes.findIndex(
              (elm) => elm.id == modele_attributs[index]
            );
            if (k >= 0) list.push(this.attributes[k]);
          }
        }
      }
      return list;
    },
    values() {
      let l = [];
      l = this.values_list.filter((elm) => elm.attribute_id == this.attribut);
      return l;
    },
  },
  watch: {
    template: {
      handler() {
        if (!this.template) this.attributs_sel = [];
      },
      deep: true,
    },
  },

  created() {},

  methods: {
    delchips(item) {
      this.attributs_sel.remove(item);
    },
    close() {
      this.$emit("close");
    },
    update_attribut() {
      let i;
      let att_name = this.attributs_list.find(
        (elm) => elm.id == this.attribut
      ).name;
      let values = this.values_list.filter(
        (elm) => elm.attribute_id == this.attribut
      );
      let val_name;
      let val_color;
      let val_ref;
      for (let index = 0; index < this.value.length; index++) {
        i = values.findIndex((elm) => elm.id == this.value[index]);
        if (i >= 0) {
          val_name = values[i].name;
          val_color = values[i].html_color;
          val_ref = values[i].ref;
        }
        i = this.attributs_sel.findIndex(
          (elm) =>
            elm.attribute_id == this.attribut &&
            elm.value_id == this.value[index]
        );
        if (i >= 0) {
          this.attributs_sel[i].value_id = this.value[index];
          this.attributs_sel[i].attribut_name = att_name;
          this.attributs_sel[i].value_name = val_name;
          this.attributs_sel[i].val_color = val_color;
          this.attributs_sel[i].val_ref = val_ref;
        } else
          this.attributs_sel.push({
            attribute_id: this.attribut,
            value_id: this.value[index],
            attribut_name: att_name,
            value_name: val_name,
            val_color: val_color,
            val_ref: val_ref,
          });
      }
      this.value = [];
    },
    filtrer() {
      let new_variant = [];
      let a = [];
      var lookup = {};

      var result = [];
      for (let index = 0; index < this.attributs_sel.length; index++) {
        var name = this.attributs_sel[index].attribute_id;
        if (!(name in lookup)) {
          lookup[name] = 1;
          result.push(name);
        }
      }
      result.forEach((element) => {
        a = [];
        let l = this.attributs_sel.filter((elm) => elm.attribute_id == element);
        for (let index = 0; index < l.length; index++) {
          a.push(l[index].value_id);
        }
        new_variant.push(a);
      });
      this.$emit(
        "close",
        this.categories,
        this.template,
        new_variant,
        this.service,
        this.types
      );
    },
  },
};
</script>
